import React from 'react'
import Link from "next/link";
import desktop from '../../assets/desktop.png'


const HeroBanner = () => {
    return (
        <div className="banner section">
            <div className="banner-information-container">
                <div className="left">
                    <div className="zentech">Try the brand new Nomo Desktop App</div>
                    <div className="header">
                        Take Control of Your Digital Assets with Nomo's New Desktop Version
                    </div>
                    <div className="information bold">Nomo's new desktop version is here! Easily manage your major cryptocurrencies and NFTs securely.
                    </div>
                    <div className="information">Take control of your digital assets with Nomo's new desktop version - the ultimate secure wallet app. Easily manage your assets across multiple blockchains and access a range of powerful features.
                    </div>
                    <Link href={"/downloads"}>
                        <div className={"button-outline"}>Download the newest Version</div>
                    </Link>
                </div>
                <div className="right">
                    <img src={desktop.src} alt=''/>
                </div>
            </div>

        </div>
    )
}

export default HeroBanner