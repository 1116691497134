import React, { useState } from 'react';
import hero_video from '../../public/assets/hero.mp4'

// import hero_video from '../../public/assets/phone.mp4'
import Link from 'next/link';



const VideoBanner = () => {
    // State to handle the display of the 'copied' feedback
    const [copied, setCopied] = useState(false);

    const navigateToPlugins = () => {
        document.getElementById('pluginsComponent').scrollIntoView({ behavior: 'smooth' });
    };

    const handleImageClick = () => {
        if (window.innerWidth < 769) {
            const link = "https://nomo.app/webon/discover.nomo.zone";
            navigator.clipboard.writeText(link).then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    };

    return (
        <>
            <div id="top-section" className="top-container">
                <div className="headline-container">
                    <h1 className="headline">Elevate Your Nomo App with Web-Enhanced Add-Ons</h1>
                    <p className="sub-headline">Introducing Nomo App WebOns:<br/> Your Gateway to a World of Possibilities</p>
                    <Link href="/webons">
                        <a className="start-link">Get started now!</a>
                    </Link>
                    <img src={'assets/qr.png'} alt="QR Code" className="top-img" onClick={handleImageClick} />
                    {copied && <span className="copied-feedback">Copied!</span>}

                </div>
                <img src={'assets/screenshots.png'} alt="Preview" className="previewed" />
            </div>
        <div id='herobanner' className='black'>
            <div className='text-container'>
                <div style={{'--i': '1'}}>Decentralized</div>
                <div style={{'--i': '1'}}>Reliable</div>
                <div style={{'--i': '2'}}>Secure</div>
                <div style={{'--i': '3'}}>Innovative</div>
                <div style={{'--i': '4'}}>Nomo</div>
            </div>
            <video autoPlay muted loop>
                <source src={hero_video}/>
            </video>
        </div>
        </>
    )
}

export default VideoBanner